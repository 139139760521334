<template>
  <div>
      <CCard>
      <CCardHeader>
        <CIcon name="cil-settings" /><strong>Error Logs</strong>
      </CCardHeader>
      <CCardBody>
          <CRow class="col-md-12 col-12">
             <label>Filter By date : </label>
              <CRow class="col-md-3">
                <CCol>
                  <span class="form-control" @click="()=>date_modal=true" style="cursor: pointer;">
                    {{ changeDateFormateForCalender(startDate)}}   <b>-</b>  {{ changeDateFormateForCalender(endDate) }}
                  </span>
                </CCol>
              <CModal
                title="Select Date Range"
                :show.sync="date_modal"
                :centered="true"
                scrollable
                color="dark"
                
              >
              <date-range-picker 
                :from="date_data.endDate"
                :to="date_data.startdate"
                :future="false"
                :panel="date_data.panel"
                :showControls="false" 
                @select="update"/>
              <template #footer>
                <CButton @click="date_modal = false" color="danger">Close</CButton>
              </template>
              </CModal>
              </CRow>
            <!-- <label>Filter By date : </label>
              <date-time-picker
                class="ml-2 col-md-6 col-12"
                :endDate="endDate"
                :singleDate="false"
                :startDate="startDate"
                @onChange="dateFilter"
              /> -->
               <CButton
              class=" ml-3 col-md-2 col-12"
              color="success"
              @click="getLogs()"
              >Get Logs</CButton
            >
            </CRow>
            <CRow>
            <CCol>
              <CRow class="col-md-12">
                <label>Total Rows : {{ totalRows }}</label>
              </CRow>
            </CCol>
            <CCol class="pb-2">
              <div class="card-header-actions">
                <small class="text-muted">
                  <multiselect
                size="sm"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect>
          </small>
              </div>
            </CCol>
          </CRow>
      <!-- Main table element -->
      <div class="table_div">
      <CDataTable
      :items="items"
      :dark="false"
      :items-per-page="tableperpage"
      sorter
      hover
      striped
      border
      small
      fixed
      table-filter
      light
    >
    <template #browse_headers="{ item }">
      <td>
        <span class="res_text">{{ item.browse_headers }}</span>
      </td>
    </template>
    <template #rawHeaders="{ item }">
      <td>
        <span class="res_text">{{ item.rawHeaders }}</span>
      </td>
    </template>
    <template #emessage="{ item }">
      <td>
        <span class="res_text">{{ item.emessage }}</span>
      </td>
    </template>
</CDataTable>
    </div>
    <div class="pagination">
    <a href="#" @click="getLogs('prev')">&laquo;</a>
    <a href="#" class="active">{{pageno}}</a>
    <a href="#" @click="getLogs('next')">&raquo;</a>
  </div>
    </CCardBody></CCard>

  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ManagerService from '../../services/managerControlService'
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";

  export default {
    components: {Multiselect, DateTimePicker},
    data() {
      return {
        date_data:{startdate:null,enddate:null,panel:null},
        date_modal:false,
        tableperpage:20,
        perPage:[20,50,100],
        pageno:1,
        items: [],
        totalRows: 1,
        currentPage: 1,
        filter: null,
        filterOn: [],
        startDate: new Date(),
        endDate: new Date(),
        input:{ edate: new Date(),
        sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),}
      }
    },
    computed: {
      
    },
   async mounted() {
      if(window.localStorage.getItem('user') == 'null' || window.localStorage.getItem('user') != 'manager') {
        alert('Please Enter User Name and Password')
          window.localStorage.setItem("user", null);
          window.localStorage.setItem("status", null);
          window.localStorage.setItem("email", null);
        this.$router.push('/pages/login')
      }
      if(localStorage.getItem("log_table") != null)
      this.tableperpage = parseInt(localStorage.getItem("log_table"));
      this.dateFilter({startDate:new Date(this.startDate.setHours(0,0)),endDate:new Date(this.endDate.setHours(23,59))});
      this.getLogs()
      
    },
    methods: {
      changeDateFormateForCalender(d){
      let dateToFormate
      if(typeof d != "string") {
        dateToFormate = new Date(d);
      } else {
        let dd = d.split("T")[0]
        dateToFormate = new Date(dd);
      } 
     return dateToFormate.toLocaleDateString("en-US", {day: "numeric",year: "numeric",month: "long"})
    },
    update(values) {
      this.date_data.startdate = values.from
      this.date_data.enddate = values.to
      this.date_data.panel = values.panel
      this.dateFilter({startDate:new Date(values.from),endDate:new Date(values.to)})
      this.date_modal = false;
    },
      dateFilter(data) {
      this.input.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.input.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.startDate = data.startDate
      this.endDate = data.endDate
    },
      checkPage(){
          localStorage.setItem("log_table", this.tableperpage);
          this.getLogs()
        },
       async getLogs(txt){
        try {
          if(txt == 'next'){this.pageno ++}
          if(txt == 'prev'){
            if(this.pageno != 1){
            this.pageno --
            }
          }
          let payload = {pageSize: this.tableperpage, currentPage: this.pageno,filters:{sdate:this.input.sdate,edate:this.input.edate}}
          let response = await ManagerService.ErrorLogs(payload)
          this.items = response.message
          this.totalRows = this.items.length
        } catch(e){
            console.error("Error in login")
            console.log(e)
        }
        },
    }
  }
</script>

<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 22px;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

</style>
<style>
.table_div{
  overflow: auto; height: 500px;
}
.table_div thead { position: sticky !important; top: 0 !important; z-index: 1 !important; }
</style>
